<template>
  <div>
    <ClientHeader :client="client" />
    <v-row>
      <v-col cols="9">
        <v-select
          :append-icon="'mdi-arrow-down'"
          v-model="selectedNewStage"
          outlined
          label="Workflow Stages"
          :items="workflowStages"
          :item-text="'friendly_name'"
          :item-value="'id'"
        />
      </v-col>
      <v-col cols="3">
        <v-btn color="primary" class="mt-2" @click="updateClientStage" :disabled="!selectedNewStage"
          >Apply
        </v-btn>
      </v-col>

      <v-col cols="12" md="9" v-if="workflowLog.length > 0">
        <div v-for="item in workflowLog">
          <v-card elevation-10 class="pa-8 mt-2">
            <v-card-title>{{ item.date }}</v-card-title>
            <v-card-subtitle v-if="typeof item.entries == 'object'">
              {{ item.entries.message }}
            </v-card-subtitle>
            <v-card-subtitle v-for="e in item.entries" class="d-flex justify-space-between px-4">
              <v-row>
                <v-col cols="4" class="text-start">{{ e.friendly_name }}</v-col>
                <v-col cols="4" class="text-center">{{ e.workflow_analyzer }} </v-col>
                <v-col cols="4" class="text-end">{{ e.message }}</v-col>
              </v-row>
            </v-card-subtitle>
          </v-card>
        </div>
      </v-col>

      <v-row v-else-if="workflowLog.length === 0">
        <v-col cols="12">
          <p class="ml-3 text-body-1">
            No workflow history yet. This will auto-populate as the client moves through each of the
            stages of our process.
          </p>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import clientsService from "@/services/clients.service";
import ClientService from "@/services/clients.service";
import ClientHeader from "@/components/Client/ClientHeader.vue";

export default {
  name: "Workflow",
  components: {ClientHeader},
  data() {
    return {
      workflowStages: [],
      workflowLog: [],
      selectedNewStage: "",
      client:null,
      clientId: undefined,
    };
  },

  mounted() {},

  methods: {
    getCurrentClient(){
      ClientService.getOneClient(this.clientId).then(res=>{
        this.client = res
      })
    },
    updateClientStage() {
      clientsService.editClient(this.clientId, {
        workflow_stage: this.selectedNewStage,
      }).then(() => {
        this.selectedNewStage = "";
        this.getHistory();
      });
    },
    getHistory() {
      clientsService.getWorkflowLog(this.clientId).then((res) => {
        this.workflowLog = res;
      });
    },
  },
  created() {
    this.clientId = this.$route.params.clientId;
    clientsService.getWorkflowStages().then((res) => {
      this.workflowStages = res;
    });
    this.getHistory();
    this.getCurrentClient()
  },
};
</script>

<style lang="scss" scoped></style>
